<template>
  <div class="image-upload">
    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>{{ $t("media.dropFilesHere") }}</h3>
    </div>
    <div class="image-upload" v-show="!edit">
      <div class="text-center">
        <label for="image">
          <span v-if="!files.length">
            <span v-if="defaultImage">
              <img
                :src="defaultImage"
                style="max-width: 50%;"
              />
            </span>
            <span v-else>
              <div class="card card-body">
                <h4 class="pt-2 text-dark">
                  {{ $t("media.dropFileHereOrClickToUpload") }}
                </h4>
              </div>
            </span>
          </span>
          <span v-else>
            <img
              :src="files[0].url"
              style="max-width: 50%;"
            />
          </span>
        </label>
      </div>
      <div class="text-center">
        <file-upload
          extensions="gif,jpg,jpeg,png,webp"
          accept="image/png,image/gif,image/jpeg,image/webp"
          name="image"
          class="btn btn-primary"
          :drop="!edit"
          v-model="files"
          @input-filter="inputFilter"
          @input-file="inputFile"
          ref="upload"
          v-show="!files.length && !defaultImage"
        >
          {{ uploadButtonText }}
        </file-upload>
        <button
          v-show="files.length || defaultImage"
          type="button"
          class="btn btn-secondary m-2"
          @click.prevent="remove"
        >
          {{ removeButtonText }}
        </button>
      </div>
    </div>

    <div class="image-edit" v-show="files.length && edit">
      <div class="image-edit-image" v-if="files.length">
        <img v-if="files[0].url" ref="editImage" :src="files[0].url" />
      </div>
      <div class="text-center">
        <button
            type="submit"
            class="btn btn-primary m-2"
            @click.prevent="editSave"
        >
          {{ cropButtonText }}
        </button>
        <button
          type="button"
          class="btn btn-secondary m-2"
          @click.prevent="$refs.upload.clear"
        >
          Anuleaza
        </button>
      </div>
    </div>
  </div>
</template>

<style>
.image-upload .image-upload .rounded-circle {
  width: 200px;
  height: 200px;
}
.image-upload .text-center .btn {
  margin: 0 0.5rem;
}
.image-upload .image-edit-image {
  max-width: 100%;
}
.image-edit-image {
  height: 500px;
}
.image-upload .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.image-upload .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.cropper-container {
  max-width: calc(100% + 30px);
}

.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>

<script>
import FileUpload from "vue-upload-component";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";

export default {
  name: "imageUploadCropper",
  props: [
    "aspectRatio",
    "defaultImage",
    "uploadButtonText",
    "cropButtonText",
    "removeButtonText"
  ],
  components: {
    FileUpload
  },
  data() {
    return {
      files: [],
      edit: false,
      cropper: false
    };
  },
  watch: {
    edit(value) {
      if (value) {
        this.$emit("cropMode", true);

        this.$nextTick(function() {
          if (!this.$refs.editImage) {
            return;
          }

          var cropper_opts = {
            aspectRatio: this.aspectRatio,
            viewMode: 1,
            crop: function() {}, //to show the crop box manually
            minCanvasWidth: 200,
            minCanvasHeight: 200,
            minCropBoxWidth: 50,
            minCropBoxHeight: 50,
            minContainerWidth: 200, //decides the size of image
            minContainerHeight: 200, //decides the size of image
            autoCropArea: 1,
            modal: true, // Show the black modal
            guides: true, // Show the dashed lines for guiding
            center: true, // Show the center indicator for guiding
            highlight: true, // Show the white modal to highlight the crop box
            background: true, // Show the grid background,
            scalable: true,
            rotatable: true,
            checkOrientation: true,
            cropBoxResizable: true,
            dragMode: "move"
          };

          let cropper = new Cropper(this.$refs.editImage, cropper_opts);
          this.cropper = cropper;
        });
      } else {
        this.$emit("cropMode", false);

        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = false;
        }
      }
    }
  },
  methods: {
    editSave() {
      this.edit = false;
      let oldFile = this.files[0];
      let binStr = atob(
        this.cropper
          .getCroppedCanvas()
          .toDataURL(oldFile.type)
          .split(",")[1]
      );
      let arr = new Uint8Array(binStr.length);
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type });
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true
      });
      this.$emit("fileUpdated", this.files);
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.$nextTick(function() {
          this.edit = true;
        });
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.$swal.fire({
            icon: "error",
            title: this.$t("users.notAPicture")
          });
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
    remove() {
      this.files = [];
      this.$emit("fileRemoved");
    }
  }
};
</script>
